import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import Layout from "../components/layout"
import CookieService from '../services/CookieService'
import DisplayService from "../services/DisplayService"
import AppStore from "../services/AppStore"
import SEO from "../components/seo"
import editIcon from "../images/edit.png"
import AlbumService from "../services/AlbumService"
import UserService from "../services/UserService"
import BucketService from "../services/BucketService"
import Image from "../components/image"
import AlbumEditForm from "../components/album-edit-form"
import Portal from '../components/portal'

import loadingIcon from "../images/loader.svg"
import assetDeleteIcon from '../images/asset-delete.png'
import sortIcon from '../images/sort.png'
import pendingIcon from "../images/in-progress.png"
import './albums.css'

Amplify.configure(config)

class AlbumsPage extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.initYears = []

    this.state = {
      loading: true,
      events: [],
      eventYears: [],
      others: [],
      selectedAlbumType: 'EVENT',
      selectedAlbum: null
    }

    this.updateAlbum = this.updateAlbum.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    this.userInfo = await UserService.getUserInfo()

    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    const albumList = await AlbumService.getAlbums()

    const data = this.getAlbumsPerType(albumList)
    this.initYears = AppStore.get("ALBUMS-PAGE-OPENED-YEARS")
    if (!this.initYears) this.initYears = [data.eventYears.sort((a, b) => (b.year - a.year))[0].year]

    if (this._isMounted) {
      this.setState({
        loading: false,
        events: data.events,
        eventYears: data.eventYears.sort((a, b) => (b.year - a.year)).map((eventYear, index) => ({
          ...eventYear,
          isVisible: this.initYears.indexOf(eventYear.year) > -1
        })),
        others: data.others
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getAlbumsPerType (albumList) {
    const events = []
    const others = []
    const eventYears = []
    albumList.forEach(album => {
      switch(album.type) {
        case 'EVENT':
          events.push(album)
          const year = album.albumId.split('|')[2].substr(0, 4)
          if (!eventYears.filter(evt => evt.year - year === 0).length) eventYears.push({ year: +year, isVisible: false })
          break;
        case 'PEOPLE':
          break;
        default:
          others.push(album)
      }
    })

    return {
      events,
      others,
      eventYears
    }
  }

  
  async updateAlbum(alBumData) {
    const albumList = await AlbumService.updateAlbumInfo(alBumData)
    const data = this.getAlbumsPerType(albumList)

    if (this._isMounted) {
      this.setState({
        loading: false,
        events: data.events,
        others: data.others,
        selectedAlbum: null
      })
    }
  }

  async deleteAction(albumId) {
    if (!albumId) return
    const conf = window.confirm(
      "ATTENTION - z'êtes vraiment vraiment sur de vouloir effacer cet album? (les photos seront toujours disponibles mais l'information de groupage sera non récuperable."
    )
    if (conf) {
      const res = await AlbumService.deleteAlbum(albumId)
      if (!res) {
        alert("there was a pb")
      } else {

        const albumList = await AlbumService.getAlbums()
      
        const data = this.getAlbumsPerType(albumList.filter(album => (album.albumId !== albumId)))

        if (this._isMounted) {
          this.setState({
            loading: false,
            events: data.events,
            eventYears: data.eventYears,
            others: data.others
          })
        }
      }
    }
  }

  getAlbumBtns (album) {
    let btns = []
    if (album.albumId.split('|')[0] === this.userInfo.username) { // my album
      // album sort button
      btns.push(<div key='btn-sort' className='album-btn'>
          <a
          href={`/album-sort/${album.albumId}`}
          title="Gérer l'ordre des médias"
          id={`Day${album.albumId}`}
          >
            <img src={sortIcon} alt="Gérer l'ordre des médias" ></img>
          </a>
        </div>)
      // edit button
      btns.push(<div key='btn-edit' className='album-btn'>
        <button onClick={() => this.setState({ selectedAlbum: album })}><img src={editIcon} alt="Effacer l'album"></img></button>
      </div>)
      // delete button
      btns.push(<div key='btn-del' className='album-btn'>
        <button onClick={() => this.deleteAction(album.albumId)}><img src={assetDeleteIcon} alt="Effacer l'album"></img></button>
      </div>)
    }
    return btns
  }

  toggleYearDisplay (year) {
    if (this.initYears.indexOf(year) < 0) this.initYears.push(year)
    else this.initYears = this.initYears.filter(y => y !== year)
    AppStore.set("ALBUMS-PAGE-OPENED-YEARS", this.initYears)

    this.setState({
      eventYears: this.state.eventYears.map(eventYear => {
        return {
          ...eventYear,
          isVisible: eventYear.year - year === 0 ? !eventYear.isVisible : eventYear.isVisible
        }
       })
    })
  }

  getRenderContent () {
    if (this.state.loading) {
      return <div className="loader" style={{ margin: '10rem' }}><img src={loadingIcon} alt="loading" /></div>
    }

    const content = []
    let yearCtrl = null
    // const albumTypes = AlbumService.getAlbumTypes()
    if (this.state.events.length && this.state.selectedAlbumType === 'EVENT') {
      const yearContent = []
      let eventContent = []
      this.state.events.sort((a, b) => {
        let aTime = new Date(a.startDate).getTime()
        let bTime = new Date(b.startDate).getTime()
        if (aTime > bTime) return -1
        if (aTime < bTime) return 1
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      }).forEach((album, index) => {

        if (!yearCtrl) {
          yearCtrl = album.startDate.getFullYear()
        } else {
          if (yearCtrl - album.startDate.getFullYear() !== 0) {
            yearContent.push({ year: yearCtrl, content: eventContent })
            yearCtrl = album.startDate.getFullYear()
            eventContent = []
          }
        }

        let peopleEl = []
        if (album.peoples.length) {
        peopleEl.push(<div className="album-people-list" key="event-people-wrap">Avec {album.peoples.sort((a, b) => { return a.split('|')[2].localeCompare(b.split('|')[2]); }).map((people, index) => {
          const separator = index ? (index === album.peoples.length - 1 ? ' & ' : ', ') : ''
          return <span key={`det_${album}_${people}`}>{separator} {people.split('|')[2]}</span>
          })}</div>)
        }  

          eventContent.push(
            <div className="album-block-wrapper" key={`event_${index}`}>
              <div className="image-polaroid">
                <a
                  key={`wrap_${index}`}
                  href={`/album/${album.albumId}`}
                  title={album.name}
                  id={`Day${album.albumId}`}
                >
                  <div className="album-block">
                    <div className="album-block-image">
                      {album.primeMedia.key ? <Image data={album.primeMedia} /> : <img src={pendingIcon} style={{ maxWidth: '100px' }} alt='pending data' />}
                    </div>
                    <div className="album-block-content">
                      
                      <div style={{ fontWeight: 500, margin: '.125rem 0', fontSize: '.9rem', lineHeight: 1.25 }}> 
                        {album.startDate.getTime() !== album.endDate.getTime() ? <span><span>{DisplayService.getFormattedDate(album.startDate)}</span><span style={{ fontWeight: 300 }}> - </span><span>{DisplayService.getFormattedDate(album.endDate)}</span></span> : <span>{DisplayService.getFormattedDate(album.startDate)}</span>}
                        <span style={{ color: '#aaaaaa', fontStyle: 'italic' }}> {album.user} </span>
                      </div>
                      <div>
                        <span style={{ fontSize: '1.125rem', fontWeight: 500, margin: '.125rem 0', lineHeight: .9 }}> {album.name}</span>
                      </div>
                      <div style={{ fontSize: '.9rem', lineHeight: 1.125 }}>{album.description}</div>
                      {peopleEl}

                    </div>
                    {album.count ? <div className="album-media-count"><span className="album-media-count-inner">{album.count} médias</span></div> : ''}
                  </div>
                </a>
                <div 
                  key={`btns_${index}`}
                  className="album-btns"
                >
                  {this.getAlbumBtns(album)}
                </div>
              </div>
            </div>)

          if (index === this.state.events.length - 1) { // last item -> add to year entry
            let findIndex = yearContent.findIndex(item => item.year === yearCtrl)
            if (findIndex > -1) {
              // year entry already exists - update content
              yearContent[findIndex].content = eventContent
            } else {
              yearContent.push({ year: yearCtrl, content: eventContent })
            }
          }
        })

      yearContent.forEach((yearData, index) => {
        let isVisible = this.state.eventYears.filter(eventYear => eventYear.year === yearData.year)[0].isVisible
        let blockClassNames = ['album-year-block'] 
        if (!index) blockClassNames.push('first-block')
        if (index === yearContent.length - 1) blockClassNames.push('last-block')
        if (isVisible) blockClassNames.push('selected')
        content.push(<div key={`year-block-${yearData.year}`} className={blockClassNames.join(' ')}>
            <div className={isVisible ? 'album-year-block-inner selected' : 'album-year-block-inner'}></div>
            <button className='album-year-block-button no-select' onClick={() => this.toggleYearDisplay(yearData.year)}>
              <span>{yearData.year}</span>
              <div className='album-year-count'>{yearData.content.length}</div>
            </button>
          </div>)

        if (isVisible) {
          content.push( <div key={`content-block-${yearData.year}`}>
          <div className={"album-wrapper event-wrapper"}  >
            {yearData.content}
          </div>
        </div> )
        }

      })

    }

    if (this.state.others.length  && this.state.selectedAlbumType === 'OTHER') {
      const othersContent = []
      this.state.others.sort((a, b) => {
        
        // TODO -> sort 'others' albums... (mine first?...)

        if (a.user > b.user) return 1
        if (a.user < b.user) return -1
        
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0


      }).forEach((album, index) => {

        let peopleEl = []
        if (album.peoples.length) {
        peopleEl.push(<div className="album-people-list" key="event-people-wrap">Avec {album.peoples.sort((a, b) => { return a.split('|')[2].localeCompare(b.split('|')[2]); }).map((people, index) => {
          const separator = index ? (index === album.peoples.length - 1 ? ' & ' : ', ') : ''
          return <span key={`det_${album}_${people}`}>{separator} {people.split('|')[2]}</span>
          })}</div>)
        }  

          othersContent.push(
            <div className="album-block-wrapper" key={`other_${index}`}>
              <div className="image-polaroid">
                <a
                  key={`wrap_${index}`}
                  href={`/album/${album.albumId}`}
                  title={album.name}
                  id={`Day${album.albumId}`}
                >
                  <div className="album-block">
                    <div className="album-block-image">
                      {album.primeMedia.key ? <Image data={album.primeMedia} /> : <img src={pendingIcon} style={{ maxWidth: '100px' }} alt='pending data' />}
                    </div>
                    <div className="album-block-content">
                      <div style={{ color: '#aaaaaa', fontStyle: 'italic', margin: '.125rem 0' }}>{BucketService.getBucketInfo(album.private).display}</div>
                      <div style={{ fontSize: '1.125rem', fontWeight: 500, margin: '.125rem 0' }}> {album.name}</div>
                      <div style={{ fontSize: '.9rem', lineHeight: 1.125 }}>{album.description}</div>
                    </div>
                    {album.count ? <div className="album-media-count"><span className="album-media-count-inner">{album.count} médias</span></div> : ''}
                  </div>
                </a>
                <div 
                  key={`btns_${index}`}
                  className="album-btns"
                >
                  {this.getAlbumBtns(album)}
                </div>
              </div>
            </div>)
        })

      content.push( <div key='type-other'>
        <div className="album-wrapper">
          {othersContent}
        </div>
        </div> )  
    }

    return content
  }


  render () {
    let portalContent = ''
    if (this.state.selectedAlbum) {
      portalContent = <Portal>
        <div className="modal">
          <div className="modal-background"></div>
            <div className="modal-content new-album">
              <AlbumEditForm 
                selectedAlbum={this.state.selectedAlbum}
                updateAlbum={this.updateAlbum} />
              <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ selectedAlbum: null })}></button>
            </div>
          </div>
        </Portal>
    }

    return (
      <Layout menu="ALBUMS">
        <SEO title="Albums" />
        <h1>Albums</h1>
        <div className='album-type-selector'>
          <div className='album-type-selector-inner no-select'>
            <button onClick={() => this.setState({ selectedAlbumType: 'EVENT' })} className={this.state.selectedAlbumType === 'EVENT' ? 'selected' : ''}>EVENEMENTIELS</button>
            <button onClick={() => this.setState({ selectedAlbumType: 'OTHER' })} className={this.state.selectedAlbumType === 'OTHER' ? 'selected' : ''}>THEMATIQUES</button>
          </div>
        </div>
        {this.getRenderContent()}
        {portalContent}
      </Layout>
      )
  }
}

export default withAuthenticator(AlbumsPage, {
  includeGreetings: false
 })